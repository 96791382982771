<template>
  <v-container fluid>
    <v-row class="pa-4">
      <v-col cols="12" md="3" class="d-flex align-center justify-center">
        <v-img :src="end.logoCinza" style="height: 5rem !important;" contain></v-img>
      </v-col>
      <v-col cols="12" md="6" class="d-flex align-center justify-center textHeader fontMontserrat">
        <p>Pró-Clínica, sua Clínica de Urologia em <span class="textBlue">{{ end.cidade }}</span></p>
      </v-col>
      <v-col cols="12" md="3" class="d-flex align-center justify-center">
        <button class="bgBlue btnHeader white--text" @click="abrirDialogWhats">Agende sua Consulta</button>
      </v-col>
    </v-row>

    <v-row :class="intBreakPoint >= 2 && 'px-16'">
      <v-col cols="12" md="4" offset-md="2"
        :class="intBreakPoint < 2 ? 'd-flex align-center justify-center pl-8 pr-8' : 'd-flex align-center justify-center'">
        <div class="d-flex flex-column align-start justify-center" style="gap: 1.5rem">
          <h1 class="textBlue" style="font-weight: 700; line-height: 1;">Seu sistema urinário é essencial para o seu
            bem-estar. Cuide bem
            dele.</h1>
          <p class="textGray" style="font-weight:700;">
            Nossos urologistas estão prontos para te atender com excelência, comprometimento e foco total na sua
            <span class="textBlue">qualidade de vida.</span>
          </p>
          <button class="btnCTA" @click="abrirDialogWhats">Agendar minha consulta</button>
          <div :class="intBreakPoint >= 2 ? 'd-flex align-center pa-0 ma-0' : 'd-flex flex-column align-center pa-0 ma-0'"
            style="width: 100%">
            <v-col cols="12" md="6" :class="intBreakPoint >= 2 ? 'd-flex align-center pl-0' : 'd-flex align-center'">
              <div class="d-flex align-center justify-center details" style="gap: 1rem">
                <img src="@/assets/lpsEspecialidade/dermatologia/logoConsultas.png" style="width: 2rem;">
                <span class="textBlue" style="font-weight: bold;">CONSULTAS</span>
              </div>
            </v-col>
            <v-col cols="12" md="6" :class="intBreakPoint >= 2 ? 'd-flex align-center pr-0' : 'd-flex align-center'">
              <div class="d-flex align-center justify-center details" style="gap: 1rem">
                <img src="@/assets/lpsEspecialidade/dermatologia/logoExames.png" style="width: 2rem;">
                <span class="textBlue" style="font-weight: bold;">EXAMES</span>
              </div>
            </v-col>
          </div>
          <!-- <p class="textBlue mt-4" style="font-weight: 800;">Nossos Urologistas estão prontos para te atender com
            excelência, comprometimento e foco total na sua qualidade de vida.</p> -->
        </div>
      </v-col>
      <v-col cols="12" md="6"
        :class="intBreakPoint < 2 ? 'd-flex align-center justify-center pl-8 pr-8' : 'd-flex align-center justify-center'">
        <v-img :src="end.uroloHeroImg" style="height: 40rem !important;" contain></v-img>
      </v-col>
    </v-row>

    <v-row :class="intBreakPoint >= 2 ? 'bgBlue pa-16' : 'bgBlue py-16'">
      <v-col cols="12" md="4" offset-md="2"
        :class="intBreakPoint < 2 ? 'd-flex flex-column align-center justify-center pl-8 pr-8' : 'd-flex flex-column align-center justify-center'">
        <p class="white--text mediumFont" style=" font-weight: 800; ">
          Confie nos <span class="textBlue2">Urologistas</span> da <span class="textBlue2">Pró-Clínica</span> para cuidar
          bem da sua sáude
        </p>
        <p class="white--text " style="font-weight: 400; ">
          Na Pró-Clínica você pode agendar a sua consulta com um Urologista em {{ end.cidade }} em minutos através do
          WhatsApp com preço justo e datas próximas.
        </p>
        <button class="btnCTA" @click="abrirDialogWhats">Agendar minha consulta</button>
      </v-col>
      <v-col cols="12" md="4"
        :class="intBreakPoint < 2 ? 'd-flex align-center justify-center pl-8 pr-8' : 'd-flex align-center justify-center'">
        <v-img :src="end.consultas" style="height: 20rem !important; cursor: pointer;" contain
          @click="abrirDialogWhats"></v-img>
      </v-col>

      <v-col cols="12" md="12"
        :class="intBreakPoint < 2 ? 'd-flex align-center justify-center pl-8 pr-8' : 'd-flex align-center justify-center'">
        <p class="white--text mt-4 mediumFont" style=" font-weight: 800;">Tudo isso sem filas, sem carência e sem
          necessidade de convênio. <br />
          A melhor maneira de cuidar da sua saúde de maneira preventiva.</p>
      </v-col>
    </v-row>

    <v-row :class="intBreakPoint >= 2 ? 'pa-16' : 'pt-16'">
      <v-col cols="12" md="8" offset-md="2" class="mb-16">
        <v-img src="@/assets/lpsEspecialidade/urologia/prostata.png" style="width: 100% !important;" contain></v-img>
      </v-col>
      <v-col cols="12" md="6" offset-md="2"
        :class="intBreakPoint < 2 ? 'd-flex flex-column align-center justify-center pl-8 pr-8' : 'd-flex flex-column align-center justify-center'">
        <h2 class="textBlue"
          :style="intBreakPoint >= 2 ? 'font-size: 2rem; line-height: 2rem;' : 'font-size: 1.5rem; line-height: 2rem;'">
          Em dúvida se você deve marcar uma consulta com um <span class="textBlue2">Urologista</span>?
        </h2>
        <div class="mt-8">
          <p class="textBlue mediumFont" style="font-weight: 400; line-height: 1;">Existem várias situações ou sintomas
            em que é
            aconselhável agendar uma consulta com um urologista.</p>
        </div>
      </v-col>
    </v-row>

    <v-row :class="intBreakPoint >= 2 && 'px-16'">
      <v-col cols="12" md="4" offset-md="2"
        :class="intBreakPoint < 2 ? 'd-flex flex-column align-center justify-center pl-8 pr-8' : 'd-flex flex-column align-center justify-center'">
        <p class="textBlue align-self-start" style="font-weight: 800;">Confira as principais:</p>
        <v-list dense>
          <v-list-item v-for="item, index in items" :key="index"
            :class="intBreakPoint >= 2 ? 'textBlue mt-8' : 'textBlue mt-4'">
            <div class="d-flex align-center justify-start">
              <v-img src="../../assets/lpsEspecialidade/dermatologia/ellipse.png"
                style="width: 1rem !important; height: 1rem !important;" contain></v-img>
              <span style="font-weight: 700;" class="pl-4">
                {{ item }}
              </span>
            </div>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col cols="12" md="4"
        :class="intBreakPoint < 2 ? 'd-flex flex-column align-center justify-center pl-8 pr-8' : 'd-flex flex-column align-center justify-center'">
        <v-img :src="end.uroloSituacoes" style="height: 20rem !important;" contain></v-img>
      </v-col>
    </v-row>

    <v-row :class="intBreakPoint >= 2 && 'px-16'" :style="intBreakPoint >= 2 && 'margin-top: 10rem'">
      <v-col cols="12" md="4" offset-md="2"
        :class="intBreakPoint < 2 ? 'd-flex flex-column align-center justify-center pl-8 pr-8' : 'd-flex flex-column align-center justify-center'">
        <p class="textBlue" style="font-weight: 800;">
          Um urologista pode ajudar a monitorar sua saúde do sistema urinário, fazer avaliações de risco e fornecer
          orientações sobre hábitos de vida saudáveis e medidas preventivas.
        </p>
        <p class="textGray" style="font-weight: 800;">*É fundamental lembrar que essas são apenas algumas situações ou
          sintomas que podem indicar a necessidade de consultar um Urologista. Sempre consulte um profissional de saúde
          qualificado para avaliar e diagnosticar corretamente sua condição e fornecer o tratamento adequado.</p>
        <p class="textBlue" style="font-weight: 800;">
          Se identificou com um ou mais sintomas? É hora de agendar sua consulta com um Urologista em {{ end.cidade }}
        </p>
        <button class="btnCTA" @click="abrirDialogWhats">Agendar minha consulta</button>
      </v-col>
      <v-col cols="12" md="4"
        :class="intBreakPoint < 2 ? 'd-flex flex-column align-center justify-center pl-8 pr-8' : 'd-flex flex-column align-center justify-center'">
        <v-img :src="end.uroloTratamento" style="height: 20rem !important;" contain></v-img>
      </v-col>
    </v-row>

    <v-row :class="intBreakPoint >= 2 && 'px-16'" :style="intBreakPoint >= 2 && 'margin-top: 5rem'">
      <v-col cols="12" md="8" offset-md="2"
        :class="intBreakPoint < 2 ? 'd-flex flex-column align-start justify-center pl-8 pr-8' : 'd-flex flex-column align-start justify-center'">
        <h1 class="textBlue">
          Veja o que um
          <span class="textBlue2">especialista</span>
          tem para dizer
        </h1>

        <div
          :class="intBreakPoint < 2 ? 'd-flex flex-column align-start justify-center pl-8 pr-8' : 'd-flex align-center justify-space-around'"
          style="width: 100%; gap: 2rem; margin-top: 2rem;">
          <div class="d-flex flex-column align-center justify-center" style="width: 100%;">
            <h3 class="textBlue">Sobre a frequência:</h3>
            <video width="100%" :height="intBreakPoint >= 2 ? '600px' : '400px'" controls controlslist="nodownload"
              class="my-8">
              <source src="https://abaremarketing.com/media/proClinica_urologista1.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </div>

          <div class="d-flex flex-column align-center justify-center" style="width: 100%;">
            <h3 class="textBlue">Sobre os sinais:</h3>
            <video width="100%" :height="intBreakPoint >= 2 ? '600px' : '400px'" controls controlslist="nodownload"
              class="my-8">
              <source src="https://abaremarketing.com/media/proClinica_urologista2.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </div>
        </div>

        <p>
          <span style="font-weight: 800;">Dr. Bruno Azevedo</span><br />
          Urologista<br />
          CRM: 149068
        </p>
      </v-col>
    </v-row>

    <v-row :class="intBreakPoint >= 2 && 'px-16'" :style="intBreakPoint >= 2 && 'margin-top: 5rem'">
      <v-col cols="12" md="8" offset-md="2"
        :class="intBreakPoint < 2 ? 'd-flex flex-column align-center justify-center pl-8 pr-8' : 'd-flex flex-column align-center justify-center'">
        <p class="textBlue" style="font-weight: 700;">Além desses fatores, a frequência ideal de consultas rotineiras com
          um urologista pode variar
          dependendo da idade e do estado geral de saúde de uma pessoa que não tenha problemas urológicos conhecidos. Aqui
          está uma orientação geral para a frequência das consultas, mas é importante ressaltar que essas recomendações
          podem variar de acordo com as diretrizes médicas específicas e a avaliação individual de um médico:</p>
      </v-col>
      <v-col cols="12" md="6" offset-md="2"
        :class="intBreakPoint < 2 ? 'd-flex flex-column align-center justify-center pl-8 pr-8' : 'd-flex flex-column align-center justify-center'">
        <p class="textGray"><span class="textBlue" style="font-weight: 700;">Adultos jovens (idade entre 18 e 30
            anos):</span> Para adultos jovens
          sem fatores de risco significativos para problemas urológicos, é recomendado fazer uma consulta com um
          urologista a cada 2 a 4 anos.</p>
        <p class="textGray"><span class="textBlue" style="font-weight: 700;">Adultos de meia-idade (idade entre 30 e 50
            anos):</span> A cada 1 a 2
          anos, especialmente se houver fatores de risco urológico, como histórico familiar de doenças urológicas,
          infecções urinárias recorrentes ou problemas prostáticos.</p>
        <p class="textGray"><span class="textBlue" style="font-weight: 700;">Adultos mais velhos (idade acima de 50
            anos):</span> Anualmente ou a
          cada 6 meses, dependendo do estado de saúde do sistema urinário e das recomendações médicas individuais.</p>
      </v-col>
      <v-col cols="12" md="4" offset-md="2"
        :class="intBreakPoint < 2 ? 'd-flex flex-column align-center justify-center pl-8 pr-8' : 'd-flex flex-column align-center justify-center'">
        <p class="textBlue" style="font-weight: 700;">
          *Essas recomendações são gerais e podem variar para cada pessoa. Se você tiver dúvidas sobre a frequência ideal
          de consultas com um Urologista com base em sua idade e histórico de saúde, é recomendado consultar um médico
          para obter orientações personalizadas.
        </p>
      </v-col>
      <v-col cols="12" md="12"
        :class="intBreakPoint < 2 ? 'd-flex align-center justify-center pl-8 pr-8' : 'd-flex align-center justify-center'"
        :style="intBreakPoint >= 2 && 'margin-top: 5rem; margin-bottom: 5rem'">
        <div style="width: 30rem" :class="intBreakPoint < 2 && 'mb-16'">
          <button class="btnCTA" @click="abrirDialogWhats">Agendar minha consulta</button>
        </div>
      </v-col>
    </v-row>

    <footer>
      <v-row style="background-color: #f5f5f5">
        <v-col cols="12" md="12"
          :class="intBreakPoint < 2 ? 'd-flex align-center justify-center pl-8 pr-8' : 'd-flex align-center justify-center'">
          <a href="https://abaremarketing.com/" target="_blank">
            <v-img style="max-width: 3rem" src="@/assets/abareLogoPreto.webp" contain></v-img>
          </a>
          <router-link to="/">
            <v-img style="max-width: 8rem" :src="end.logoPreto" contain></v-img>
          </router-link>
        </v-col>
      </v-row>
    </footer>
  </v-container>
</template>

<script>
export default {
  name: 'Urologia',

  data() {
    return {
      items: [
        "Dor ou desconforto persistente na região lombar ou abdominal.", "Dificuldade ou dor ao urinar.", "Sangue na urina ou alterações na cor da urina.", "Dificuldade em manter a ereção ou outros problemas de saúde sexual.", "Histórico familiar de doenças urológicas."
      ]
    };
  },
  methods: {
    abrirDialogWhats() {
      this.$root.$emit('show-whats');
    },
  },
  computed: {
    intBreakPoint() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 0;
        case 'sm':
          return 1;
        case 'md':
          return 2;
        case 'lg':
          return 3;
        case 'xl':
          return 4;
        default:
          return 0;
      }
    },

    end() {
      return this.$store.getters.unidade;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap');


* {
  font-family: Inter, Montserrat, Roboto, sans-serif !important;
}

@media (max-width: 600px) {
  .mediumFont {
    font-size: 1.2rem !important
  }

  h1 {
    line-height: 2rem !important;
    font-size: 1.4rem !important;
  }
}

@media (min-width: 1920px) {
  h1 {
    font-size: 2rem !important;
  }
}

h1 {
  font-size: 1.5rem;
}


.mediumFont {
  font-size: 1.5rem
}

.textHeader {
  font-size: 1.3rem;
  font-weight: 700;
  color: #000;
  text-align: center;
  margin: 0;
  padding: 0;
}

.textBlue {
  color: #085A9E !important;
}

.textBlue2 {
  color: #8FCCFF;
}

.textGray {
  color: #817F7E
}

.bgBlue {
  background-color: #085A9E;
}

.btnHeader {
  padding: 0.5rem 1rem;
  font-weight: 700;
  text-transform: uppercase;
}

.btnCTA {
  background-color: #25D366;
  color: #fff;
  width: 100%;
  padding: 1rem 0;
  font-weight: 700;
  text-transform: uppercase;
}

.details {
  /* border: 1px solid #ccc; */
  margin: 0;
  width: 100% !important;
  height: 4rem;
  /* box-shadow: rgb(0, 0, 0, 0.3) 0px 3px 0; */
}
</style>